<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { ref } from "vue";
import axios from "axios";
import {useRouter} from "vue-router"

const body = document.getElementsByTagName("body")[0];

const store = useStore();
const router = useRouter();

const user = ref({
  msisdn: "",
  password: ""
})
const signIn = async () => {

  // if (!user.value.msisdn || !user.value.password ){
  //   console.log("all input fields must be filled")
  // }
  console.log("button click")

  let data = {
    "msisdn" : parseInt(user.value.msisdn),
    "password": user.value.password,
  }
  const baseurl = process.env.VUE_APP_API_IDENTITY_URL
  console.log("baseurl", baseurl)
  // let url = 'https://identity.maybets.com/user/login';
  // console.log("url", url)
  // console.log("user", data)
  try {
   const response =  await axios.post(baseurl,data)
    console.log("response", response.data)
    console.log("response", response.status)
    if(response.status === 200){
      store.commit('setUser', response.data)
      localStorage.setItem('user', response.data)
      await router.push('/dashboard')

    }
  }catch (error){
    console.log("error", error)
  }

  
}
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
  body.classList.remove("bg-gray-100");
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
  body.classList.add("bg-gray-100");
});
</script>
<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your phone number and password to sign in</p>
                </div>
                <div class="card-body">
                  <form role="form">
                    <div class="mb-3">
                      <argon-input
                        id="phone number"
                        type="phone number"
                        placeholder="Phone Number"
                        name="phone number"
                        size="lg"
                        v-model="user.msisdn"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        v-model="user.password"
                        size="lg"
                      />
                    </div>
                    <argon-switch id="rememberMe" name="remember-me"
                      >Remember me</argon-switch
                    >
                    <div class="text-center">
                      <p @click="signIn" class="btn mb-0 bg-gradient-success btn-lg w-100 null mt-4">
                        Sign in
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  "Attention is the new currency"
                </h4>
                <p class="text-white position-relative">
                  The more effortless the writing looks, the more effort the
                  writer actually put into the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
