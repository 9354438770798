<script setup>
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import {computed, onMounted, ref} from "vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const startDate = ref(new Date());
const endDate = computed(() => {
  const endDate = new Date(startDate.value);
  endDate.setDate(endDate.getDate() + 7);
  return endDate;
});

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
const pickerDate = ref([startDate.value, endDate.value]);
onMounted(() => {
  console.log('Formatted start Date:', formatDate(startDate.value));
  console.log('Formatted end Date:', formatDate(endDate.value));
});

</script>
<template>
  <div class="py-4 container-fluid">
    <div class="my-4"  style="display: flex; flex-direction: row; justify-content: space-between">
      <div class="date-picker-container" style="margin-left: auto;">
        <VueDatePicker v-model="pickerDate" range multi-calendars :enable-time-picker="false"  class="datePicker" />
      </div>

    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Total Bets"
              value="2,000"
              description="Bets Placed"
              :icon="{
                component: 'ni ni-books',
                background: 'bg-gradient-primary',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Total Stake"
              value="Ksh. 200,300"
              description="Gross Stake on bets"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Excise Duty (Stake)"
              value="Ksh. 12,500"
              description="To be paid"
              :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Excise Duty (Paid)"
              value="Ksh. 10,000"
              description="Paid successfully"
              :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
              title="Excise Duty (Unpaid)"
              value="Ksh. 10,000"
              description="Not yet paid"
              :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
                title="Total Winnings"
                value="Ksh. 2.08M"
                description="Total Winnings"
                :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
                title="Total Winning Bets"
                value="546"
                description="No of won bets"
                :icon="{
                component: 'ni ni-books',
                background: 'bg-gradient-danger',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
                title="WHT"
                value="Ksh. 145,500"
                description="WHT on Winnings"
                :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
                title="WHT (Paid)"
                value="Ksh. 100,000"
                description="Paid successfully"
                :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
          <div class="col-lg-3 col-md-6 col-12">
            <mini-statistics-card
                title="WHT (Unpaid)"
                value="Ksh. 45,500"
                description="Not yet paid"
                :icon="{
                component: 'ni ni-paper-diploma',
                background: 'bg-gradient-success',
                shape: 'rounded-circle',
              }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.datePicker{
  background-color: #8392ab;
}
</style>
