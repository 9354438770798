import {useRouter} from "vue-router"

export const useAuth = {
    state: {
        user: null
    },

    mutations:{
        setUser(state, user){
            state.user = user
        }
    },

    action:{
        logout ({commit}) {
            try {
              commit('setUser', null)
      
            }catch (error) {
              console.error('Logout error:', error.message);
              throw error;
            }
          },

        async checkUser ({commit}) {
            const router = useRouter();
            const user = localStorage.getItem("user");
            if (!user) {
                commit("setUser", JSON.parse(user));
            }else {
               await router.push('signin')
            }

        }
    }
}