import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import WithTax from "../views/WithTax.vue";
import GamingTax from "../views/GamingTax.vue";
import KRA from "../views/KRA.vue";
import REM from "../views/REM.vue";
import WHTPRN from "../views/WHTPRN.vue";
import GPRN from "../views/GamingPRN.vue";
import Settings from "../views/Settings.vue"
import store from "@/store";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard",
    meta: {requiresAuth: true},
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {requiresAuth: true},
  },
  {
    path: "/remittance",
    name: "Remittance",
    component: REM,
    meta: {requiresAuth: true},
  },
  {
    path: "/excise-duty",
    name: "Excise Duty",
    component: Tables,
    meta: {requiresAuth: true},
  },
  {
    path: "/withholding-tax",
    name: "Withholding Tax",
    component: WithTax,
    meta: {requiresAuth: true},
  },
  {
    path: "/gaming-tax",
    name: "Gaming Tax",
    component: GamingTax,
    meta: {requiresAuth: true},
  },
  {
    path: "/kra",
    name: "KRA",
    component: KRA,
    meta: {requiresAuth: true},
  },
  {
    path: "/wht-prn",
    name: "Withholding Tax PRN",
    component: WHTPRN,
    meta: {requiresAuth: true},
  },
  {
    path: "/gaming-prn",
    name: "Gaming Tax PRN",
    component: GPRN,
    meta: {requiresAuth: true},
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {requiresAuth: true},
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: {requiresAuth: true},
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
    meta: {requiresAuth: true},
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
    meta: {requiresAuth: true},
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {requiresAuth: true},
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  store.dispatch("checkUser")

  if(to.meta.requiresAuth && !store.state.user) {
    next('/signin')
  }else {
    next()
  }
})

export default router;

export class useRouter {
}