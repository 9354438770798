<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import ArgonButton from "@/components/ArgonButton.vue";
import swal from "sweetalert2";

const Settings = ref({
  cannot_withdraw_more_than_cumulative_deposit: 1,
  cannot_withdraw_more_than_cumulative_stake: 1,
  cannot_withdraw_more_than_cumulative_winning: 1,
  withdraw_status: 1,
  withdrawal_before_minimum_spend: 1,
  withdrawal_daily_limit: 0,
  withdrawal_maximum_amount: 0,
  withdrawal_minimum_amount: 0,
});

onMounted(() => {
  const storedSettings = localStorage.getItem('Settings');
  if (storedSettings) {
    Settings.value = JSON.parse(storedSettings);
  }
});

const isRegistered = ref(false);

onMounted(() => {
  const storedStatus = localStorage.getItem('isRegistered');
  if (storedStatus === 'true') {
    isRegistered.value = true;
  }
});

const submitSettings = async () => {
  try {
    const baseurl = process.env.VUE_APP_API_TAX_URL;
    const url = `${baseurl}/settings`;
    const response = await axios.post(url, Settings.value);
    swal.fire('Success!', response.data.data, 'success');

    localStorage.setItem('Settings', JSON.stringify(Settings.value));
  } catch (error) {
    console.error('Error saving settings:', error.response ? error.response.data : error.message);
    swal.fire('Error', error.response ? error.response.data.message : error.message, 'error');
  }
};

const registerCompany = async () => {
  try {
    const baseurl = process.env.VUE_APP_API_TAX_URL;
    const url = `${baseurl}/register`;
    const response = await axios.post(url);

    if (response.data.status === 200) {
      isRegistered.value = true;
      localStorage.setItem('isRegistered', 'true');
      swal.fire('Success!', response.data.data, 'success');
    }
  } catch (error) {
    console.error('Error registering company:', error.response ? error.response.data : error.message);
    swal.fire('Error', error.response ? error.response.data.message : error.message, 'error');
  }
};
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="">
      <button
          type="button"
          class="btn btn-secondary me-2"
          @click="registerCompany"
          :disabled="isRegistered"
      >
        {{ isRegistered ? 'Registered' : 'Register' }}
      </button>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-header pb-0 p-3">
            <div class="row">
              <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0">KRA Settings</h6>
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <form @submit.prevent="submitSettings">
              <div class="row">
                <div class="col-md-6 mb-3">
                  <label for="deposit" class="form-label">Cannot Withdraw More Than Cumulative Deposit</label>
                  <input v-model="Settings.cannot_withdraw_more_than_cumulative_deposit" type="number" class="form-control" id="deposit" />
                </div>
                <div class="col-md-6 mb-3">
                  <label for="stake" class="form-label">Cannot Withdraw More Than Cumulative Stake</label>
                  <input v-model="Settings.cannot_withdraw_more_than_cumulative_stake" type="number" class="form-control" id="stake" />
                </div>
                <div class="col-md-6 mb-3">
                  <label for="winning" class="form-label">Cannot Withdraw More Than Cumulative Winning</label>
                  <input v-model="Settings.cannot_withdraw_more_than_cumulative_winning" type="number" class="form-control" id="winning" />
                </div>
                <div class="col-md-6 mb-3">
                  <label for="status" class="form-label">Withdraw Status</label>
                  <input v-model="Settings.withdraw_status" type="number" class="form-control" id="status" />
                </div>
                <div class="col-md-6 mb-3">
                  <label for="before-min-spend" class="form-label">Withdrawal Before Minimum Spend</label>
                  <input v-model="Settings.withdrawal_before_minimum_spend" type="number" class="form-control" id="before-min-spend" />
                </div>
                <div class="col-md-6 mb-3">
                  <label for="daily-limit" class="form-label">Withdrawal Daily Limit</label>
                  <input v-model="Settings.withdrawal_daily_limit" type="number" class="form-control" id="daily-limit" />
                </div>
                <div class="col-md-6 mb-3">
                  <label for="max-amount" class="form-label">Withdrawal Maximum Amount</label>
                  <input v-model="Settings.withdrawal_maximum_amount" type="number" class="form-control" id="max-amount" />
                </div>
                <div class="col-md-6 mb-3">
                  <label for="min-amount" class="form-label">Withdrawal Minimum Amount</label>
                  <input v-model="Settings.withdrawal_minimum_amount" type="number" class="form-control" id="min-amount" />
                </div>
              </div>
              <div class="text-end">
                <argon-button type="submit" color="dark" variant="gradient" class="mx-2">
                  Save Settings
                </argon-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



